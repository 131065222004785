<template>
    <div class="filtered__traits container">
        <div class="filtered__traits__header box">
            <router-link
                :to="{
                    name: 'nft-details',
                    params: { 
                        projectId: $route.params.projectId, 
                        artId: artId,
                    }
                }"
            >
                Back to Token
            </router-link>
            <h1>{{$route.query.attributeTitle}}: {{$route.params.attributeValue}}</h1>
        </div>
        <div class="filtered__traits__grid box">
            <thumbnail-trait
                v-for="trait in traitsFiltered" :key="trait.id"
                title="The Big Bang"
                :art_number="trait.id"
                :image_url="trait.thumbnailImageUrl ? trait.thumbnailImageUrl : placeholderImg"
                :detail_id="trait.id"
                :project_id="trait.projectId"
            >
            </thumbnail-trait>
        </div>
    </div>
</template>

<script>
import ThumbnailTrait from '../components/ThumbnailTrait.vue';
import api from '@/service/api';

export default {
	name: "filtered-traits",
	components: { ThumbnailTrait },

	data() {
		return {
            artId: null,
            traitsFiltered: [],
            imagesUrl: process.env.VUE_APP_IMAGES_URL,
            projectId: this.$route.params.projectId,
            
        };
	},
    async mounted() {
        await this.getTraitsFilterByAttribute();
    },
    computed: {
        placeholderImg(){
            return `${this.imagesUrl}/projects/${this.projectId}/placeholder_ungenerated.png`;
        }, 
    },
    methods: {
        async getTraitsFilterByAttribute() {
            if(this.$route.params.projectId == 2) {
                this.$router.push({name: 'project', params: { projectId: this.$route.params.projectId } });
                return;
            };
            this.artId = this.$route.params.artId
            try {
                const { content } = await api.projects.arts.traits.getTraitsFilter(
                    this.$route.params.projectId,
                    this.$route.params.attribute,
                    this.$route.params.attributeValue,
                    1024
                );
                this.traitsFiltered = content.filter((data) => data.status === "GENERATED" );
            }catch (error) {
                console.error(error);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.container {
    max-width: 1200px;
    margin: 42px auto;
}
.box {
    padding: 16px;
}
.filtered__traits {
	display: grid;
	grid-template-rows: repeat(2, auto);
	grid-row-gap: 16px;
    h1 {
        margin: 0px;
        line-height: 46px;
        font-style: italic;
    }
	&__header {
		display: flex;
		flex-direction: column;
		row-gap: 16px;
		a {
			text-decoration: none;
			color: var(--color-emerald);
            line-height: 18px;
		}
	}
	&__grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 16px;
        row-gap: 32px;
	}
}
@include respond-to("medium and down") {
    .container {
        margin: 16px auto;
    }
}
@include respond-to("small and down") {
    .container {
        margin: 0px;
    }
    .filtered__traits {
        &__grid {
            grid-template-columns: unset;
        }
    }
}
</style>
